import { brandSet, freeSet } from "@coreui/icons";
import { BiriteInvoice } from "./invoices";
import Strings from "./strings";
import { pdf } from "@react-pdf/renderer";
import { DateTime } from "luxon";
import crypto from "crypto";

const testAccts = [
  "brettnaul@gmail.com",
  "brettnaul@icloud.com",
  "jasonellisrobinson@gmail.com",
  "jarobb3@gmail.com",
  //"jason@anansii.com",
];

const testAccountNumbers = {
  BIRITE: ["22222222"],
  ANANSII: ["22222222"],
};

export const customerSignInProviders = {
  BIRITE: ["google"],
};

const anansiiPaymentMethodIds = {
  TEST: ["l4oWw1MWJWtGAjzbQ16yIrBBBRBM91CaaZEov"],
  PROD: [
    "M0MqBP7gp4CNYwwwwPk0c8zwvepJ7YFpPYq0r", // Grasshopper
    "RDeA8dr7pxtqXrMKPx0oFkVOx4OoMmhyd40YD", // SVB
    "vLN5ODLQJySqevBvznqJuPXNL7eaJ7ImBbDPy", // Sivo
  ],
};

export const isAnansiiPaymentMethod = (paymentMethodId) => {
  const env = isLocal() ? "TEST" : "PROD";
  return anansiiPaymentMethodIds[env].includes(paymentMethodId);
};

export const toTitleCase = (str) => {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};

export const partnerOrgs = ["birite.com", "anansii.com", "gmail.com"];

export const isEnabled = (feature, userEmail) => {
  if (testAccts.includes(userEmail)) return true;

  switch (feature) {
    case "check-collection":
      return false;
    default:
      return false;
  }
};

export const isPartnerOrg = (email) => {
  if (testAccts.includes(email)) return true;

  return partnerOrgs.some((org) => email.indexOf(org) !== -1);
};

export const userOrg = (email) => {
  return partnerOrgs.find((org) => email.indexOf(org) !== -1);
};

export const isInSameOrg = (emailA, emailB) => {
  return userOrg(emailA) === userOrg(emailB);
};

export const partnerOrgFromEmail = (email) => {
  let tokens = email.split("@");
  return tokens[tokens.length - 1];
  //return org == "anansii.com" ? "birite.com" : org;
};

export const isCheckPayingClient = (account) => {
  if (account.Terms.includes("ACH")) return false;
  if (account.Terms.includes("BAD DEBT")) return false;
  if (account.Terms.includes("CC")) return false;

  return true;
};

export const accountStatus = (account) => {
  if (!account) return null;
  return account.AmtOverdue > 0 ? statuses.LATE : statuses.CURRENT;
};

export const getAccountStatus = (overdueAmount) => {
  return overdueAmount > 0 ? statuses.LATE : statuses.CURRENT;
};

export const gate = false;

export const notificationDisplayTime = 4000;

export const unwantedSheetColumns = ["Rep", "DivisionNum", "Age"];

export const Keys = {
  RETURN: 13,
  BACKSPACE: 8,
  COMMA: 188,
  TAB: 9,
  SEMICOLON: 186,
};

export const consentVersions = {
  TERMS: "1.0",
  PRIVACY: "1.0",
};

export const legalDocURLs = {
  TERMS:
    "https://docs.google.com/document/d/e/2PACX-1vT_6wtHkeL9h88ebMy-QPKHxmXhi0CT9GRvbtvd91dqSwyHo7O2U4QHi397SCxmjg/pub?embedded=true",
  PRIVACY:
    "https://docs.google.com/document/d/e/2PACX-1vRoEWLSqHCtuyINgLHmyYLQownHW63zXBIJfLC1MHj1r8YxPMhG3-2jiIxsjPayi6y5OyQniHxEVXqg/pub?embedded=true",
};

export const PAYMENT_METHOD_OPTIONS = {
  ACH: "bank",
  CARD: "card",
  CHECK: "check",
  LOCKBOX: "lockbox",
};

export const PAYMENT_METHOD_TYPES = {
  BANK: "bank",
  CARD: "card",
  NEW_CARD: "new_card",
  SAVED_CARD: "saved_card",
  CREDITS: "credits",
  INSTALLMENTS: "installments",
};

const CARD_SURCHARGES = {
  BIRITE: 0.03,
  ANANSII: 0.03,
};

export const getCardSurchargeRate = (customer) => {
  return CARD_SURCHARGES[customer.toUpperCase()];
};

export const INSTALLMENT_OFFERS = [
  {
    payments: 3,
    feePct: 0.015,
  },
  // {
  //   payments: 5,
  //   feePct: 0.025,
  // },
];

export const isEmpty = (v) => {
  if (Array.isArray(v) && v.length === 0) return true;

  if (!v || v === "") return true;

  return false;
};

export const isValidEmail = (email) => {
  if (!email) return false;
  if (email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) return true;

  return false;
};

export const isValidEmailList = (inputList) => {
  // each item is a Contact -- { name: XXX, email: YYY }
  // return true if all are valid; else, return false
  return inputList.every((c) => isValidEmail(c.email));
};

export const getSheetLinkFromText = (input) => {
  return input.match(
    /https:\/\/docs.google.com\/spreadsheets\/d\/[a-zA-Z0-9-_/#=]+/i
  );
};

export const getSheetIdFromUrl = (input) => {
  let found = input.match(/\/spreadsheets\/d\/[a-zA-Z0-9-_]+/i);
  if (!found) return false;
  return found[0].split("/")[3];
  //return found[0];
};

export const messageAdapter = (msgObj) => {
  //console.log(msgObj);
  // first, check if it is a new style msgObj by checking if the field is "toEmail" or "toEmails"
  if ("toEmails" in msgObj) {
    //console.log("new message object");
    return msgObj;
  }

  //console.log("old school message object");

  // if not, then we have an old school message object
  // to bring it up to speed, we need to make the toEmail & ccEmail fields arrays of Contact objs

  return {
    toEmails: [
      {
        name: null,
        email: msgObj.toEmail,
      },
    ],
    ccEmails: msgObj.ccEmail
      ? [
          {
            name: null,
            email: msgObj.ccEmail,
          },
        ]
      : [],
    type: msgObj.type,
    sentTo: msgObj.sentTo,
    sentAt: msgObj.sentAt,
    message: msgObj.message,
  };
};

export const messageAdapterV2 = (msg) => {
  // v2
  if ("to_emails" in msg) return msg;

  // v1
  if ("toEmails" in msg)
    return {
      ...msg,
      to_emails: msg.toEmails,
      cc_emails: msg.ccEmails,
      from_email: msg.fromEmail,
      sent: msg.sentAt,
      cqk_account_id: msg.sentTo,
      cqk_customer: msg.distributorName.split(".")[0],
    };

  // v0
  return {
    ...msg,
    to_emails: [
      {
        name: null,
        email: msg.toEmail,
      },
    ],
    cc_emails: msg.ccEmail
      ? [
          {
            name: null,
            email: msg.ccEmail,
          },
        ]
      : [],
    sent: msg.sentAt,
    cqk_account_id: msg.sentTo,
  };
};

export const convertToCents = (amount) => {
  return Math.round(parseFloat(amount) * 100);
};

const local = "http://localhost:5001/helloworld-bfc92/us-central1";
const prod = "https://anansii.com";
export const isLocal = () => {
  return window.location.host.includes("localhost");
};
export const host = isLocal() ? local : prod;
export const getEndpoint = (api) => {
  return host + api;
};

export const getEnvironment = (hostname) => {
  return hostname.match(/anansii\.com$/) ? "PROD" : "TEST";
};

export const getCardPointeiFrameSourceUrl = (hostname) => {
  const e = getEnvironment(hostname);
  const subdomain = e === "PROD" ? "fts" : "fts-uat";
  return `https://${subdomain}.cardconnect.com/itoke/ajax-tokenizer.html`;
};

export const requestOptions = (data) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  return {
    method: "POST",
    headers: myHeaders,
    body: data,
  };
};

export const doFetch = async (path, body) => {
  let domain = window.location.host.includes("localhost") ? local : prod;
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  let opts = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  };

  //console.log(opts.body);
  let response = await fetch(domain + path, opts);
  if (!response.ok) throw new Error(response);

  return await response.json();
};

export const getAllPaidInvoiceIdsFromPayments = (payments) => {
  const ids = [];
  let payment_invoice_ids;
  if (payments) {
    for (let p of payments) {
      if (!p.paid_invoices || !Array.isArray(p.paid_invoices)) continue;
      if (p.payment_type.toUpperCase() === "LOAN_PAYMENT") continue;
      payment_invoice_ids = p.paid_invoices.map((i) => i.id);
      ids.push([...payment_invoice_ids]);
    }
  }
  return ids.flat();
};

export const isVisibleLoanPayment = (loanpayment) => {
  return [
    TRANSACTION_STATUS.PENDING,
    TRANSACTION_STATUS.SCHEDULED,
    TRANSACTION_STATUS.SUCCESS,
  ].includes(loanpayment.status);
};

const isLoanPayment = (payment) => {
  return payment.payment_type.toUpperCase() === "LOAN_PAYMENT";
};
export const formatScheduledPaymentsString = (
  scheduledPayment,
  showDate = true
) => {
  const isloanpayment = isLoanPayment(scheduledPayment);
  const formattedDate = formatTimestampAsDate(scheduledPayment.scheduled, {
    dateStyle: "medium",
  });
  const datestr = showDate ? `${formattedDate} - ` : "";

  const paymentnumberstr = scheduledPayment.hashid
    ? `Payment #${scheduledPayment.hashid} `
    : "";

  const numinseriesstr = isloanpayment
    ? ` [${scheduledPayment.num_in_series} of ${scheduledPayment.series_length}] `
    : "";

  const totalstr = isloanpayment
    ? formatAsMoney(
        (scheduledPayment.principal_amount + scheduledPayment.interest_amount) /
          100
      )
    : formatAsMoney(
        (scheduledPayment.amount +
          scheduledPayment.surcharge -
          scheduledPayment.credits_used) /
          100
      );

  const invoicespaid = formatAsPaidInvoiceString(scheduledPayment);
  const invoicespaidstr =
    invoicespaid === "" ? "" : ` for Inv #${invoicespaid}`;

  return `${datestr} ${paymentnumberstr}${numinseriesstr}(${totalstr})${invoicespaidstr}`;
};

export const formatAsPaidInvoiceString = (payment) => {
  if (
    !payment ||
    !payment.paid_invoices ||
    !Array.isArray(payment.paid_invoices)
  )
    return "";
  return payment.paid_invoices.map((inv) => inv.id).join(", #");
};

export const formatAsMoney = (number) => {
  if (!number || number === "") number = 0;
  return parseFloat(number).toLocaleString("en", {
    style: "currency",
    currency: "USD",
  });
};

export const formatAsPhoneNumber = (phoneNumberString) => {
  let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = match[1] ? "+1 " : "";
    let phone = [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
      ""
    );
    return phone;
  }
  return null;
};

export const overdueNumStyles = (amt_overdue) => {
  if (amt_overdue > 0) return { color: "#e55353" };
  return null;
};

export const formatDate = (date, options = { dateStyle: "medium" }) => {
  return date.toLocaleString("en-US", options);
};

// information on format options here: https://www.w3schools.com/jsref/jsref_tolocalestring.asp
export const formatTimestampAsDate = (timestamp, options = {}) => {
  if (!timestamp) return "";
  try {
    return formatDate(timestamp.toDate(), options);
  } catch (err) {
    return "";
  }
};

export const formatTimestampAsMediumDate = (timestamp) => {
  const dt = timestampAsLuxonDateTime(timestamp);
  return dt.toLocaleString(DateTime.DATE_MED);
};

export const formatTimestampAsMediumDateTime = (timestamp) => {
  const dt = timestampAsLuxonDateTime(timestamp);
  return dt.toLocaleString(DateTime.DATETIME_MED);
};

export const invoiceStatus = (i) => {
  // FUTURE WORK: think about timezone issues screw things up
  // if balance is zero, return "current"
  if (i.Balance > 0) {
    let today = new Date();
    let due = i.DueDate.toDate();

    // if the due date is the past, return "late"
    if (today > due) return statuses.LATE;

    // if the due date is in the past after 7 days, return "due"
    let later = today;
    let dueThreshold = 7;

    later.setDate(later.getDate() + dueThreshold);

    if (later >= due) return statuses.DUE;
  }

  //if none of the above, the invoice is current
  return statuses.CURRENT;
};

export const isPastDueInvoice = (invoice) => {
  if (
    invoice.due_date &&
    invoice.status &&
    invoice.status.toUpperCase() === "OPEN"
  ) {
    const today = new Date().setHours(0, 0, 0, 0);
    const due = invoice.due_date.toDate().setHours(0, 0, 0, 0);

    // if the due date is the past, return "late"
    if (today > due) return true;
  }

  return false;
};

export const isPaymentForPastDueInvoice = (p, openInvoices) => {
  if (!p || !p.paid_invoices || !openInvoices) return false;

  const paidInvIds = p.paid_invoices.map((inv) => inv.id);
  let invoiceObj;
  for (let id of paidInvIds) {
    invoiceObj = openInvoices.find((item) => item.id === id);
    if (!invoiceObj) {
      //console.log("did not find paid invoice id in list of open invoices")
      continue;
    }

    if (isPastDueInvoice(invoiceObj)) {
      //console.log("is past due invoice ... paynment cannot be cancelled");
      return true;
    }
  }
  return false;
};

export const invoiceStatusFromDueDate = (invoice) => {
  // invoice statuses: open, paid, past due
  if (
    invoice.due_date &&
    invoice.status.toUpperCase() === "OPEN" &&
    invoice.type !== "CREDIT"
  ) {
    const today = new Date().setHours(0, 0, 0, 0);
    const due = invoice.due_date.toDate().setHours(0, 0, 0, 0);

    // if the due date is the past, return "late"
    if (today > due) return "past due";
  }

  return invoice.status;
};

export const invoiceStatusBadgeColor = (displayStatus) => {
  switch (displayStatus.toUpperCase()) {
    case "PAST DUE":
    case "LATE":
      return "danger";
    case "PAID":
      return "success";
    case "OPEN":
      return "secondary";
    case "SCHEDULED":
      return "info";
    case "PENDING":
      return "warning";
    default:
      return "secondary";
  }
};

export const invoiceDisplayType = (type) => {
  if (!type) return "";

  switch (type.toUpperCase()) {
    case Strings.HISTORY_INVOICE_KEY:
      return "INV";
    case Strings.HISTORY_CREDITMEMO_KEY:
      return "CRD";
    case Strings.HISTORY_FINCHARGE_KEY:
      return "FIN";
    case Strings.HISTORY_ADJUSTMENT_KEY:
      return "ADJ";
    default:
      return "";
  }
};

export const invoiceRelatedTo = (invoice) => {
  switch (invoice.type) {
    case Strings.HISTORY_CREDITMEMO_KEY:
      return invoice.related_to_id ? `└ INV #${invoice.related_to_id}` : null;
    default:
      return null;
  }
};

export const defaultMsgs = {
  current:
    "Hello, \n\nJust checking in. Below is an update with your current status.\n\nThanks!",
  grace:
    "Hello, \n\nJust a friendly reminder that your payment is due. Please pay directly on our site. Reply to this email if you have any questions.\n\nThanks!",
  late:
    "Hello, \n\nJust a reminder that you have a past due balance. Please update us with a payment status as soon as possible.\n\nThanks!",
};

export const getDefaultPayReminderMessage = (customerName, status) => {
  switch (customerName) {
    case "birite":
      return defaultMsgs[status.toLowerCase()];
    default:
      return "";
  }
};

export const userAccountTypes = {
  SALES_REP: "rep",
  CREDIT_MANAGER: "cmgr",
  ACCOUNTS_RECEIVABLE: "ar",
  DISTRICT_MANAGER: "dmgr",
  CUSTOMER_ADMIN: "cadmin",
  ANANSII_SUPPORT: "asupport",
  ANANSII_ADMIN: "aadmin",
};

export const salesPermissionLevels = ["SALES_REP", "DISTRICT_MANAGER", "AR"];

export const isCreditManager = (userInfo) => {
  if (!userInfo) return false;
  return userInfo.accountType === userAccountTypes.CREDIT_MANAGER;
};

export const isDistrictManager = (userInfo) => {
  if (!userInfo) return false;
  return userInfo.accountType === userAccountTypes.DISTRICT_MANAGER;
};

export const isCustomerAdmin = (userInfo) => {
  if (!userInfo) return false;
  return userInfo.accountType === userAccountTypes.CUSTOMER_ADMIN;
};

export const isAnansiiSupport = (userInfo) => {
  if (!userInfo) return false;
  return userInfo.accountType === userAccountTypes.ANANSII_SUPPORT;
};

export const isAnansiiAdmin = (userInfo) => {
  if (!userInfo) return false;
  return userInfo.accountType === userAccountTypes.ANANSII_ADMIN;
};

export const isAdminUser = (userInfo) => {
  if (!userInfo) return false;
  return (
    isCustomerAdmin(userInfo) ||
    isAnansiiSupport(userInfo) ||
    isAnansiiAdmin(userInfo)
  );
};

export const isSuperAdminUser = (userInfo) => {
  if (!userInfo) return false;
  return isAnansiiSupport(userInfo) || isAnansiiAdmin(userInfo);
};

export const isArUser = (userDetails) => {
  return userDetails.role === "ar";
};

export const isAdmin = (userDetails) => {
  return userDetails.permissions_level && userDetails.permissions_level === 3;
};

export const isManager = (userDetails) => {
  return userDetails.role === "sales" && userDetails.permissions_level === 2;
};

export const statuses = {
  CURRENT: "Current",
  DUE: "Grace",
  LATE: "Late",
};

export const badgeColor = (status) => {
  switch (status) {
    case statuses.CURRENT:
      return "success";
    case statuses.DUE:
      return "warning";
    case statuses.LATE:
    case "Past Due":
      return "danger";
    default:
      return "primary";
  }
};

export const cardIcons = {
  VISA: brandSet.cibCcVisa,
  AMEX: brandSet.cibCcAmex,
  DISCOVER: brandSet.cibCcDiscover,
  MASTERCARD: brandSet.cibCcMastercard,
  CARD: freeSet.cilCreditCard,
};

export const TRANSACTION_STATUS = {
  PAID: "paid",
  SUCCESS: "succeeded",
  PENDING: "pending",
  PROCESSING: "processing",
  RECEIVED: "received",
  SCHEDULED: "scheduled",
  FAILURE: "failed",
  ERROR: "error",
  CLOSED: "closed",
  OPEN: "open",
  CREATED: "created",
  TO_PROCESS: "to_process",
  CANCELLED: "cancelled",
  PAST_DUE: "past due",
  QUEUED: "queued",
};

export const TRANSACTION_DISPLAY_STATUS = {
  PAID: "paid",
  SUCCEEDED: "succeeded",
  SUCCESS: "succeeded",
  PENDING: "pending",
  PROCESSING: "processing",
  RECEIVED: "received",
  SCHEDULED: "scheduled",
  FAILURE: "failed",
  FAILED: "failed",
  ERROR: "error",
  CLOSED: "closed",
  OPEN: "open",
  CREATED: "created",
  TO_PROCESS: "to_process",
  CANCELLED: "cancelled",
  PAST_DUE: "past due",
  QUEUED: "scheduled",
};

export const statusBadgeColor = (status) => {
  switch (status.toLowerCase()) {
    case TRANSACTION_STATUS.PAID:
    case TRANSACTION_STATUS.SUCCESS:
      return "success";
    case TRANSACTION_STATUS.PROCESSING:
    case TRANSACTION_STATUS.RECEIVED:
    case TRANSACTION_STATUS.PENDING:
      return "warning";
    case TRANSACTION_STATUS.SCHEDULED:
    case TRANSACTION_STATUS.QUEUED:
      return "info";
    case TRANSACTION_STATUS.FAILURE:
    case TRANSACTION_STATUS.CLOSED:
    case TRANSACTION_STATUS.ERROR:
    case TRANSACTION_STATUS.PAST_DUE:
      return "danger";
    default:
      return "secondary";
  }
};

export const composeAddress = (account) => {
  if (account.BillAddr.Line4)
    return account.BillAddr.Line3 + " " + account.BillAddr.Line4;

  if (account.BillAddr.Line2)
    return account.BillAddr.Line2 + " " + account.BillAddr.Line3;

  return "";
};

export const transformEmailToContact = (email) => {
  return { name: "", email: email };
};

const finishGoogleAuth = (event, windowName, callback) => {
  let allowedSources = ["https://anansii.com", "http://localhost:3000"];
  if (!allowedSources.includes(event.origin)) return;
  console.log(event.source.name);
  if (event.source.name !== windowName) return;

  let { data } = event;

  if (data === "success") callback();
  else console.error("something went wrong");
};

export const execGoogleAuth = (url, type, callback) => {
  console.log("start google auth");

  let previousUrl = null;
  let windowObjectReference = null;
  let windowName = "googleAuthWindow";
  let windowFeatures =
    "toolbar=no, menubar=no, width=700, height=700, top=50, left=50";

  window.removeEventListener("message", finishGoogleAuth);
  if (windowObjectReference === null || windowObjectReference.closed) {
    windowObjectReference = window.open(
      url,
      `${windowName}${type}`,
      windowFeatures
    );
  } else if (previousUrl !== url) {
    windowObjectReference = window.open(
      url,
      `${windowName}${type}`,
      windowFeatures
    );
    windowObjectReference.focus();
  } else {
    windowObjectReference.focus();
  }

  window.addEventListener(
    "message",
    (event) => finishGoogleAuth(event, `${windowName}${type}`, callback),
    false
  );
  previousUrl = url;
};

export const doGoogleAuth = (functions, type, callback) => {
  console.log("start google auth");
  let getAuthUri = functions.httpsCallable("getGoogleAuthUri");

  let previousUrl = null;
  let windowObjectReference = null;
  let windowName = "googleAuthWindow";

  getAuthUri({ type: type, local: window.location.host.includes("localhost") })
    .then((result) => {
      console.log(result);
      window.removeEventListener("message", finishGoogleAuth);

      let url = result.data;
      let windowFeatures =
        "toolbar=no, menubar=no, width=700, height=700, top=50, left=50";

      if (windowObjectReference === null || windowObjectReference.closed) {
        windowObjectReference = window.open(
          url,
          `${windowName}${type}`,
          windowFeatures
        );
      } else if (previousUrl !== url) {
        windowObjectReference = window.open(
          url,
          `${windowName}${type}`,
          windowFeatures
        );
        windowObjectReference.focus();
      } else {
        windowObjectReference.focus();
      }

      window.addEventListener(
        "message",
        (event) => finishGoogleAuth(event, `${windowName}${type}`, callback),
        false
      );
      previousUrl = url;
    })
    .catch((err) => {
      console.error("error retrieving google auth uri", err);
    });
};

export const BANK_INVITE_TYPES = {
  LINK: "link",
  AMOUNTS: "amounts",
};

export const getLastUpdatedDate = (accounts) => {
  if (!accounts || !Array.isArray(accounts)) return "";
  return accounts.reduce((previous, current) => {
    if (!current || !current.lastUpdated) return previous;
    if (previous.lastUpdate === "") return current.lastUpdated;
    if (current.lastUpdated.valueOf() < previous.valueOf()) return previous;
    return current.lastUpdated;
  }, "");
};

/**
 * PDF Functions
 *
 *
 * */

export const encodeForURI = (str) => {
  return window.btoa(unescape(encodeURIComponent(str)));
};

export const decodeFromURI = (str) => {
  return decodeURIComponent(escape(window.atob(str)));
};

export const isLinkableInvoice = (invoice) => {
  if (invoice.items === undefined) return false;
  return true;
};

const LOGO_URIs = {
  BIRITE: "https://cdn1.anansii.com/birite-logo-1.png",
  ANANSII: "https://cdn1.anansii.com/IconPurple.svg",
  AB: "https://cdn1.anansii.com/ab-produce.png",
  CW: "https://cdn1.anansii.com/cw.jpg",
};

export const getCompanyLogoURI = (account) => {
  switch (account.customer.toUpperCase()) {
    case "BIRITE":
      if (account.id.toString().startsWith("900")) return LOGO_URIs.AB;
      return LOGO_URIs.BIRITE;
    case "ANANSII":
      return LOGO_URIs.ANANSII;
    case "CW":
      return LOGO_URIs.CW;
    default:
      return "";
  }
};

export const getBiriteCompanyName = (invoice) => {
  switch (invoice.company) {
    case "1":
      return "BiRite";
    case "2":
      return "A&B";
    default:
      return "BiRite";
  }
};

export const detectBiriteCompany = (accountNumber) => {
  if (!accountNumber) return "1";

  const firstDigit = accountNumber.charAt(0);
  if (firstDigit === "9") return "2";

  return "1";
};

export const generateBiritePDFAccountInfoData = (account, invoice) => {
  return {
    "Acct#": account.id || "",
    "PO#": invoice.po || "",
    Customer: account.name || "",
    "Bill To":
      `${account.billing_address_one} ${account.billing_address_two}, ${account.billing_city}, ${account.billing_state} ${account.billing_zip}` ||
      "",
    "Ship To":
      `${account.shipping_address_one} ${account.shipping_address_two}, ${account.shipping_city}, ${account.shipping_state} ${account.shipping_zip}` ||
      "",
    Terms: account.terms || "",
    Telephone: formatAsPhoneNumber(account.phone) || "",
    "Invoice Date":
      formatTimestampAsDate(invoice.order_date, { dateStyle: "medium" }) || "",
    "Invoice Due":
      formatTimestampAsDate(invoice.due_date, { dateStyle: "medium" }) || "",
  };
};

export const generateBiritePDFCostSummaryData = (invoice) => {
  return {
    Subtotal: formatAsMoney(invoice.subtotal / 100),
    "Sales Tax": formatAsMoney(invoice.salestx / 100),
    CRV: formatAsMoney(invoice.crvtx / 100),
    SSB: formatAsMoney(invoice.ssbtx / 100),
    "Fuel Surcharge": formatAsMoney(invoice.delivery_fee / 100),
    Total: formatAsMoney(invoice.total / 100),
  };
};

export const generateBiritePDFCategoryRecapData = (invoice) => {
  if (!invoice.items) return [];

  const categoryDataArr = [];
  let found;

  const useItemCatIds =
    invoice.items[0].pe_item_class_id === null ||
    invoice.items[0].pe_item_class_id === undefined;
  for (const item of invoice.items) {
    // categoryrecapdata
    if (item.substituted) return;
    found = categoryDataArr.findIndex((el) =>
      useItemCatIds ? el.id === item.cat_id : el.id === item.pe_item_class_id
    );
    if (found === -1) {
      categoryDataArr.push({
        id: useItemCatIds ? item.cat_id : item.pe_item_class_id,
        description: useItemCatIds ? item.cat_desc : item.pe_item_class_name,
        cost: item.subtotal,
        cases: parseInt(item.num_ordered),
      });
    } else {
      categoryDataArr[found].cost += item.subtotal;
      categoryDataArr[found].cases += parseInt(item.num_ordered);
    }
  }

  return categoryDataArr.map((category) => ({
    ID: category.id,
    DESCRIPTION: category.description,
    COST: formatAsMoney(category.cost / 100),
    CASES: category.cases,
  }));
};

const formatItemDescriptionAsString = (item) => {
  const description = item.substituted
    ? item.description
    : `${item.brand} ${item.description}`;
  const extraDescription = item.extra_description
    ? `\n\n${item.extra_description}`
    : "";
  const pickupDescription = item.pickup_description
    ? `\n\n${item.pickup_description}`
    : "";

  return `${description}${extraDescription}${pickupDescription}`;
};

export const generateBiritePDFItemsData = (invoice) => {
  const itemsData = [];
  let itemDescription;
  if (invoice.items) {
    for (const item of invoice.items) {
      itemDescription = formatItemDescriptionAsString(item);
      // itemsdata
      if (item.substituted) {
        itemsData.push({
          ITEM: item.id,
          ORD: " ",
          SHP: "SUB",
          DESCRIPTION: itemDescription,
          "VENDOR ITEM #": " ",
          PACK: " ",
          SIZE: " ",
          WEIGHT: " ",
          SSB: " ",
          CRV: " ",
          "SALES TX": " ",
          COST: " ",
          AMOUNT: formatAsMoney(0),
        });
      } else {
        itemsData.push({
          ITEM: item.id,
          ORD: item.num_ordered,
          SHP: item.num_delivered,
          DESCRIPTION: itemDescription,
          "VENDOR ITEM #": item.vendor_id,
          PACK: item.pack,
          SIZE: item.size,
          WEIGHT: item.weight,
          SSB: item.ssbtx === 0 ? " " : formatAsMoney(item.ssbtx / 100),
          CRV: item.crvtx === 0 ? " " : formatAsMoney(item.crvtx / 100),
          "SALES TX": item.salestx ? "T" : " ",
          COST:
            item.cost_per_unit === 0
              ? " "
              : formatAsMoney(item.cost_per_unit / 100),
          "EXT AMOUNT":
            item.subtotal === 0 ? " " : formatAsMoney(item.subtotal / 100),
        });
      }
    }
  }

  return itemsData;
};

export const createBiriteInvoicePDF = (account, invoice, customerInfo) => {
  const accountInfoData = generateBiritePDFAccountInfoData(account, invoice);
  const costSummaryData = generateBiritePDFCostSummaryData(invoice);
  const itemsData = generateBiritePDFItemsData(invoice);
  const categoryRecapData = generateBiritePDFCategoryRecapData(invoice);

  const instance = pdf(
    <BiriteInvoice
      account={account}
      invoice={invoice}
      customerInfo={customerInfo}
      accountInfoData={accountInfoData}
      costSummaryData={costSummaryData}
      itemsData={itemsData}
      categoryRecapData={categoryRecapData}
    />
  );
  return instance.toBlob();
};

export const encodePDF = (blob) => {
  return new Promise(async (resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      let encoded = reader.result;
      let str = encoded.substr(encoded.indexOf(",") + 1);
      resolve(str);
    };
  });
};

export const customerNameFromEmail = (email) => {
  let customerName = email.substring(
    email.indexOf("@") + 1,
    email.indexOf(".")
  );

  return ["anansii", "birite"].includes(customerName) ? "birite" : customerName;
};

export const calculateOverdueBalance = (account) => {
  return (
    account.period_one_balance +
    account.period_two_balance +
    account.period_three_balance +
    account.period_four_balance
  );
};

export const sortLateAccountsToTop = (a, b) => {
  if (
    (a.status === statuses.LATE && b.status === statuses.LATE) ||
    (a.status === statuses.CURRENT && b.status === statuses.CURRENT)
  ) {
    return b.overdue_balance - a.overdue_balance;
  }

  if (a.status === statuses.LATE) return -1;

  if (b.status === statuses.LATE) return 1;

  return 0;
};

export const sortTimestamps = (date_a, date_b) => {
  // Both dates are null, consider them equal
  if (date_a === null && date_b === null) return 0;
  // A is null, B is a date; A should come before B
  if (date_a === null) return -1;
  // B is null, A is a date; A should come after B
  if (date_b === null) return 1;
  // Both A and B are dates, compare them directly
  return date_a.valueOf() - date_b.valueOf();
};

export const creditsBalanceText = (paymentAmount, creditsBalance) => {
  let str;
  const applicableCredits = Math.min(paymentAmount, creditsBalance);
  if (paymentAmount === 0) {
    str = `${formatAsMoney(
      creditsBalance / 100
    )} credit balance available to apply to next payment`;
  } else {
    str = `Apply ${formatAsMoney(applicableCredits / 100)} from credit balance`;
  }

  return str;
};

export const applyCreditMemos = (creditsApplied, creditMemos) => {
  let updated = [];
  let balanceToApply = creditsApplied;
  for (let memo of creditMemos) {
    if (balanceToApply === 0) break;
    else if (balanceToApply > Math.abs(memo.balance)) {
      updated.push({
        id: memo.id,
        path: memo.path,
        paymentAmount: memo.balance,
        account_id: memo.account_id,
      });
      balanceToApply = balanceToApply + memo.balance;
    } else {
      updated.push({
        id: memo.id,
        path: memo.path,
        paymentAmount: balanceToApply * -1,
        account_id: memo.account_id,
      });
      balanceToApply = 0;
    }
  }

  return updated;
};

export const getUserAgent = () => {
  return window.navigator.userAgent;
};

export const isPlaidLinkedBank = (paymentMethod) => {
  if (!paymentMethod.type || !paymentMethod.type.toUpperCase() === "BANK")
    return false;

  if (!paymentMethod.institution_name) return false;
  return true;
};

export const formatBankName = (bankMethod) => {
  if (bankMethod.institution_name)
    return `${bankMethod.institution_name.toUpperCase()} · ${
      bankMethod.institution_last4
    }`;

  return bankMethod.account_name.toUpperCase().replace("....", "· ");
};

export const fetchIpAddress = () => {
  return new Promise((resolve, reject) => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        const ipAddress = data.ip;
        //console.log(ipAddress);
        resolve(ipAddress);
      })
      .catch((error) => {
        console.error("Error:", error);
        reject(error);
      });
  });
};

export const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const isTodayBeforeCutoff = (payment) => {
  if (
    payment.cqk_customer &&
    testAccountNumbers[payment.cqk_customer.toUpperCase()].includes(
      payment.payer_id
    )
  )
    return true;

  const cutoffHour = 16;

  const now = new Date();

  // console.log(
  //   now.getHours(),
  //   cutoffHour,
  //   isToday(date) && now.getHours() < cutoffHour
  // );
  return now.getHours() < cutoffHour;
};

export const isImmediatePayment = (date) => {
  return isToday(date);
};

const isFutureDate = (date) => {
  return date.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0);
};

export const isFuturePayment = (date) => {
  return isFutureDate(date);
};

export const plusnDays = (date, n) => {
  const d = new Date(date.getTime());
  d.setDate(d.getDate() + n);
  return d;
};

export const plusnWeeks = (date, n) => {
  return plusnDays(date, 7 * n);
};

export const timestampAsLuxonDateTime = (t) => {
  if (!t || !t.toDate()) return "";
  return DateTime.fromJSDate(t.toDate(), { zone: "America/Los_Angeles" });
};

export const luxonDateTimeAsTimestamp = (dt, firebase) => {
  return firebase.firestore.Timestamp.fromDate(dt.toJSDate());
};

export const payMethodNameForPaymentDescriptor = (method, payType = null) => {
  if (!method) return "";
  const type = payType ? payType.toUpperCase() : method.type.toUpperCase();
  switch (type) {
    case "BANK":
      return formatBankName(method);
    case "CARD":
      return `${method.brand.toUpperCase()} - ${method.last4}`;
    case "CREDITS":
      return "ACCOUNT CREDITS";
    default:
      return "";
  }
};

export const isValidAccountNumber = (entry, customerName) => {
  if (!entry || entry.length === 0) return false;
  switch (customerName) {
    case "birite":
      return entry.length === 8;
    case "anansii":
      return entry.length === 8;
    default:
      return false;
  }
};

export const isCancellablePayment = (payment) => {
  const cancellableStatuses = [
    TRANSACTION_STATUS.SUCCESS,
    TRANSACTION_STATUS.PENDING,
    TRANSACTION_STATUS.PAID,
    TRANSACTION_STATUS.PROCESSING,
  ];
  const cancellablePaymentMethods = ["bank", "card", "credits"];
  //console.log(payment);
  return (
    cancellableStatuses.includes(payment.status) &&
    cancellablePaymentMethods.includes(payment.method_type) &&
    isToday(
      payment.scheduled ? payment.scheduled.toDate() : payment.created.toDate()
    )
  );
};

export const isScheduledInvoice = (invoice, scheduledlist, firebase) => {
  if (
    invoice.scheduled_on &&
    invoice.status &&
    invoice.status.toUpperCase() === "OPEN" &&
    invoice.scheduled_on > firebase.firestore.Timestamp.now()
  )
    return true;
  if (
    scheduledlist.includes(invoice.id) &&
    invoice.status &&
    invoice.status.toUpperCase() === "OPEN"
  )
    return true;

  return false;
};

export const isInstallmentsEligibleCustomer = (account, loans) => {
  // Is not qualified for installments
  if (
    !account.installments_credit_limit ||
    account.installments_credit_limit === 0
  )
    return false;

  // Has no open installment loans
  const openLoans = loans.filter((l) => l.status.toUpperCase() === "OPEN");
  if (openLoans && openLoans.length > 0) return false;

  return true;
};

export const groupInvoicesByType = (invoices) => {
  const grouped = invoices.reduce((prev, inv) => {
    (prev[inv.type.toUpperCase()] = prev[inv.type.toUpperCase()] || []).push(
      inv
    );
    return prev;
  }, {});
  //console.log(grouped);

  const invs = grouped["INVOICE"] || [];
  const creds = grouped["CREDIT"] || [];
  // const sortedcreds = creds.sort(
  //   (a, b) =>
  //     a.order_date.toDate().valueOf() - b.order_date.toDate().valueOf()
  // );

  const adjs = grouped["ADJUSTMENT"] || [];
  const fins = grouped["FINANCE_CHARGE"] || [];

  return {
    invs: invs,
    creds: creds,
    adjs: adjs,
    fins: fins,
  };
};

/***
 * Feature Flags
 * ~~~~~~~~~~~~~~~~~~~~
 *
 * */

const flags = {
  INSTALLMENTS: true,
};

export const isVisibleFeature = (featureName) => {
  return isLocal() || flags[featureName.toUpperCase()];
};

// List of US states
export const STATES = [
  { value: "", label: "Select State" },
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District Of Columbia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

export const formatDateInForm = (value, firebase) => {
  if (!value) return null;
  if (firebase.isFirestoreTimestamp(value)) return value.toDate();
  return value;
};

export const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const timestampToFormattedDateString = (
  timestamp,
  format,
  zone,
  firebase
) => {
  const jsdate = formatDateInForm(timestamp, firebase);
  return DateTime.fromJSDate(jsdate, {
    zone: zone,
  }).toFormat(format);
};

// Function to encrypt the data
export const encrypt = (text, b64key) => {
  const key = Buffer.from(b64key, "base64");
  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv(
    "aes-256-cbc",
    Buffer.from(key, "hex"),
    iv
  );
  let encrypted = cipher.update(text, "utf8", "hex");
  encrypted += cipher.final("hex");
  return {
    iv: iv.toString("hex"),
    encryptedData: encrypted,
  };
};
